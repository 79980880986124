import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  GiftOutlined,
  DollarOutlined,
  BankOutlined,
  PictureOutlined,
  ShopOutlined,
  PhoneOutlined,
  BellOutlined,
  TeamOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/user" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "일반 회원 리스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        path: "/helper",
        sidebar: {
          label: "헬퍼 회원 리스트",
        },
        component: loadable(() => import("../pages/user/helper/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/helper/detail/:id",
        component: loadable(() => import("../pages/user/helper/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/banner",
    sidebar: {
      icon: <PictureOutlined />,
      label: "배너 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "배너 리스트",
        },
        component: loadable(() => import("../pages/banner/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/banner/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/banner/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/matching",
    sidebar: {
      icon: <TeamOutlined />,
      label: "매칭 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "매칭 리스트",
        },
        component: loadable(() => import("../pages/matching/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/matching/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/cs",
    sidebar: {
      icon: <PhoneOutlined />,
      label: "고객선터 관리",
    },
    children: [
      {
        exact: true,
        path: "/helper/faq",
        sidebar: {
          label: "헬퍼 자주 묻는 질문 리스트",
        },
        component: loadable(() => import("../pages/cs/helper_faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/helper/faq/register",
        component: loadable(() => import("../pages/cs/helper_faq/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/helper/faq/detail/:id",
        component: loadable(() => import("../pages/cs/helper_faq/Modify")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "사용자 자주 묻는 질문 리스트",
        },
        component: loadable(() => import("../pages/cs/faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/register",
        component: loadable(() => import("../pages/cs/faq/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/detail/:id",
        component: loadable(() => import("../pages/cs/faq/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/coupon",
    sidebar: {
      icon: <GiftOutlined />,
      label: "쿠폰 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "쿠폰 목록",
        },
        component: loadable(() => import("../pages/coupon/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/coupon/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/coupon/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/push",
    sidebar: {
      icon: <BellOutlined />,
      label: "푸시알림 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "푸시알림 리스트",
        },
        component: loadable(() => import("../pages/push/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/push/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined />,
      label: "상품가격 관리",
    },
    children: [
      {
        exact: true,
        path: "/price",
        sidebar: {
          label: "상품금액 설정",
        },
        component: loadable(() => import("../pages/product/Config")),
      },
    ],
  },
  {
    exact: true,
    path: "/introduce",
    sidebar: {
      icon: <ShopOutlined />,
      label: "서비스 소개 설정",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "서비스 소개 설정",
        },
        component: loadable(() => import("../pages/introduce/Index")),
      },
    ],
  },
  {
    exact: true,
    path: "/calculate",
    sidebar: {
      icon: <DollarOutlined />,
      label: "정산 관리",
    },
    children: [
      {
        exact: true,
        path: "/fee",
        sidebar: {
          label: "정산 수수료율 설정",
        },
        component: loadable(() => import("../pages/calculate/Config")),
      },
      {
        exact: true,
        path: "/wait",
        sidebar: {
          label: "정산대기 리스트",
        },
        component: loadable(() => import("../pages/calculate/WaitList")),
      },
      {
        exact: true,
        path: "/complete",
        sidebar: {
          label: "정산완료 리스트",
        },
        component: loadable(() => import("../pages/calculate/CompleteList")),
      },
    ],
  },
];
